import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isWithinInterval from 'date-fns/isWithinInterval';
import { useMediaQuery } from 'react-responsive';
import subHours from 'date-fns/subHours';
import Slider from 'react-slick';
import { Icon } from '@dnb/eufemia/components';

import DnbStatusLink from '../components/dnb-status-link/DnbStatusLink';
import IncidentsListWrapper from '../components/incidents/IncidentsListWrapper';
import NoIncidents from '../components/incidents/NoIncidents';
import InfoCircle from '../components/info-circle/InfoCircle';
import IconHeader from '../components/layout/IconHeader';
import { addIncidentsToGroup } from '../helpers';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { chevron_left as ChevronLeft } from '@dnb/eufemia/icons';
import { chevron_right as ChevronRight } from '@dnb/eufemia/icons';

import './incidents.css';
import { useTranslation } from 'react-i18next';

export default function Incidents({ groups, incidents }) {
  const { t, i18n } = useTranslation();
  const [incidentsCount, setIncidentsCount] = useState(0);
  useEffect(() => {
    let now = new Date();
    let earliestDate = subHours(now, 48);
    let relevantIncidents = incidents.filter((e) => {
      if (e.status !== 'resolved') {
        return e;
      } else {
        const yes = isWithinInterval(new Date(e.updated_at), { start: earliestDate, end: new Date() });
        if (yes) {
          return e;
        }
      }
    });

    setIncidentsCount(relevantIncidents.length);
  }, [incidents]);

  const groupsWithIncidents = addIncidentsToGroup(groups, incidents);

  function CustomNextArrow(props) {
    const { className, onClick } = props;
    return (
      <button className={className} onClick={onClick}>
        <span className="slick-button-icon slick-button-icon--next">
          <Icon aria-hidden size="auto" icon={ChevronRight} />
        </span>
      </button>
    );
  }

  function CustomPrevArrow(props) {
    const { className, onClick } = props;
    return (
      <button className={className} onClick={onClick}>
        <span className="slick-button-icon slick-button-icon--prev">
          <Icon aria-hidden size="auto" icon={ChevronLeft} />
        </span>
      </button>
    );
  }

  let smallScreenSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  /* Get the right number of incidents for each group last 48 hours */
  const componentIncidentsCountList48h = groupsWithIncidents.map((group) => {
    let now = new Date();
    let earliestDate = subHours(now, 48);

    let incidents = group.incidents.filter((e) => {
      if (e.status !== 'resolved') {
        return e;
      } else {
        const yes = isWithinInterval(new Date(e.updated_at), { start: earliestDate, end: new Date() });
        if (yes) {
          return e;
        }
      }
    });

    return (
      <InfoCircle
        key={group.id}
        topText={t(group.name)}
        number={incidents.length.toString()}
        bottomText={incidents.length === 1 ? t('incident') : t('incidents')}
      />
    );
  });

  const componentIncidentsCountListAll = groupsWithIncidents.map((group) => {
    return (
      <InfoCircle
        key={group.id}
        topText={t(group.name)}
        number={group.incidents.length.toString()}
        bottomText={group.incidents.length === 1 ? t('incident') : t('incidents')}
      />
    );
  });

  const isSmallScreen = useMediaQuery({ query: '(max-width: 30rem)' });

  const renderContent = () => {
    if (incidentsCount > 0) {
      return (
        <>
          <div className="dnb-center-content">
            {isSmallScreen && (
              <div className="dnb-indicents-mobile-slider">
                <Slider {...smallScreenSliderSettings}>{componentIncidentsCountList48h}</Slider>
              </div>
            )}

            {!isSmallScreen && <div className="dnb-incidents-info-circles">{componentIncidentsCountList48h}</div>}
          </div>
          <IncidentsListWrapper incidents={incidents} />
        </>
      );
    } else {
      return <NoIncidents />;
    }
  };

  return (
    <main className="p_incidents-page" role="main">
      <div className="dnb-center-content">
        <DnbStatusLink href="/" icon={ChevronLeft} title={t('Back to the front page')}>
          {t('Back')}
        </DnbStatusLink>
        <IconHeader name={t('Incidents')} subtitle={t('Last 48 hours')} pageHeader icon="calendar" />
      </div>
      {renderContent()}
      <div className="dnb-center-content">
        <IconHeader name={t('Incidents')} subtitle={t('Last 90 days')} pageHeader icon="calendar" />
        {isSmallScreen && (
          <div className="dnb-indicents-mobile-slider">
            <Slider {...smallScreenSliderSettings}>{componentIncidentsCountListAll}</Slider>
          </div>
        )}

        {!isSmallScreen && <div className="dnb-incidents-info-circles">{componentIncidentsCountListAll}</div>}
      </div>
    </main>
  );
}

Incidents.propTypes = {
  groups: PropTypes.array,
  incidents: PropTypes.array,
};
