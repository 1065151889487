import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import IconHeader from '../layout/IconHeader';
import './planned-incidents.css';
import PlannedIncidentItem from './PlannedIncidentItem';

const PlannedIncidents = ({ maintenances, includedComponents }) => {
  const { t, i18n } = useTranslation();
  const renderContent = () => {
    let maintenancesList = maintenances
      .filter((e) => {
        if (e.components.length > 0) {
          if (e.components[0].id in includedComponents) {
            return e;
          }
        } else {
          return e;
        }
      });
    if (maintenancesList.length > 0) {
      return (
        <ul>
          {maintenancesList.map((i) => {
            return <PlannedIncidentItem key={i.id} item={i} />;
          })}
        </ul>
      );
    } else {
      return (
        <div className='dnb-planned-incidents__no-incidents'>
          <p>{t('There is currently no scheduled maintenance')}.</p>
        </div>
      );
    }
  };

  return (
    <div className='dnb-planned-incidents'>
      <IconHeader name={t('Scheduled maintenance')} icon='calendar' />
      {renderContent()}
    </div>
  );
};

export default PlannedIncidents;

PlannedIncidents.propTypes = {
  maintenances: PropTypes.array,
  includedComponents: PropTypes.object
};
