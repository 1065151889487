import React from 'react';
import PropTypes from 'prop-types';
import StatusOverview from './StatusOverview';
import PlannedIncidents from './PlannedIncidents';

import './frontpage-components.css';

const FrontpageComponents = ({ maintenances, groups, includedComponents }) => {
  return (
    <div className="dnb-frontpage-components">
      <StatusOverview groups={groups} />
      <PlannedIncidents maintenances={maintenances} includedComponents={includedComponents} />
    </div>
  );
};

FrontpageComponents.propTypes = {
  maintenances: PropTypes.array,
  groups: PropTypes.array,
  includedComponents: PropTypes.object
};

export default FrontpageComponents;
