import React from 'react';
import PropTypes from 'prop-types';

import HistoryHeader from '../../components/historyHeader/HistoryHeader';
import HistorySection from '../../components/historySection/HistorySection';
import useMonthSelecter from '../../hooks/useMonthSelecter';
import { addIncidentsToGroup } from '../../helpers';

export default function History({ groups, incidents }) {
  const { date, incrementWeek, decrementWeek } = useMonthSelecter(new Date());

  const resolvedIncidents = incidents.filter((e) => e.status === 'resolved');
  const mergedGroupsAndIncidents = addIncidentsToGroup(groups, resolvedIncidents);

  return (
    <div className="p_history-page">
      <div className="dnb-center-content">
        <HistoryHeader date={date} incrementWeek={incrementWeek} decrementWeek={decrementWeek} />
      </div>

      <div className="dnb-center-content">
        {mergedGroupsAndIncidents.map((group) => {
          return <HistorySection group={group} key={group.id} date={date} />;
        })}
      </div>
    </div>
  );
}

History.propTypes = {
  groups: PropTypes.array,
  incidents: PropTypes.array,
};
