import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import isSameWeek from 'date-fns/isSameWeek';
import isSameYear from 'date-fns/isSameYear';
import HistoricIncidentCard from '../incidents/HistoricIncidentCard';

export default function HistorySection({ group, date }) {
  const { t, i18n } = useTranslation();
  /* All resolved incidents in the same month / year as currently selected */
  const resolvedIncidents = group.incidents.filter((e) => {
    if (
      e.status === 'resolved' &&
      isSameWeek(date, new Date(e.started_at)) &&
      isSameYear(date, new Date(e.started_at))
    ) {
      return e;
    }
  });

  return (
    <section>
      <div className="left">
        <div>{resolvedIncidents.length} {t('incidents')}</div>
      </div>
      <div className="right">
        <ul className="incident-list">
          {resolvedIncidents.map((e) => {
            return <HistoricIncidentCard item={e} key={e.id} />;
          })}
        </ul>
      </div>
    </section>
  );
}

HistorySection.propTypes = {
  group: PropTypes.object,
  date: PropTypes.instanceOf(Date),
};
