import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CustomDnbMaintenance from '../../assets/icons/custom_dnb_maintenance.svg';
import { Icon } from '@dnb/eufemia/components';
import format from 'date-fns/format';
import FindLanguage from '../../i18n_custom';

export default function PlannedIncidentItem({ item }) {
  const { t, i18n } = useTranslation();
  const formattedStartDate = format(new Date(item.scheduled_for), 'dd.MM.yyyy, HH:mm');
  const formattedEndDate = format(new Date(item.scheduled_until), 'dd.MM.yyyy, HH:mm');
  // Assigns the first update (which gets pushed), to the incidentUpdate, this until this one
  // is fixed: https://community.atlassian.com/t5/Statuspage-questions/Keeping-maintenance-message-from-scheduled-to-ongoing/qaq-p/1879278?utm_source=atlcomm&utm_medium=email&utm_campaign=accept_as_solution_reminder&utm_content=topic
  let incidentUpdate = item.incident_updates
    .filter((e) => {
      if (e.status == 'scheduled') {
        return e;
      }
    });
  incidentUpdate = incidentUpdate[0].body;
  if (item.components > 0) {
    return (
      <li>
        <h3>
        <span className='dnb-round-icon'>
          <Icon size='auto' icon={CustomDnbMaintenance} aria-hidden />
        </span>
          {FindLanguage(item.name)}
        </h3>
        <p>{FindLanguage(incidentUpdate)}</p>
        <div className='dnb-planned-incident__time'>
          <time dateTime={formattedStartDate}>{formattedStartDate}</time>
          <span> - </span>
          <time dateTime={formattedEndDate}>{formattedEndDate}</time>
        </div>
        <h4>{t('Affected systems')}:</h4>
        <ul className='dnb-planned-incident__affected-systems'>
          {item.components.map((e) => {
            return <li key={e.id}>{t(e.name)}</li>;
          })}
        </ul>
      </li>
    );
  } else {
    return (
      <li>
        <h3>
        <span className='dnb-round-icon'>
          <Icon size='auto' icon={CustomDnbMaintenance} aria-hidden />
        </span>
          {FindLanguage(item.name)}
        </h3>
        <p>{FindLanguage(incidentUpdate)}</p>
        <div className='dnb-planned-incident__time'>
          <time dateTime={formattedStartDate}>{formattedStartDate}</time>
          <span> - </span>
          <time dateTime={formattedEndDate}>{formattedEndDate}</time>
        </div>
      </li>
    );
  }
}

PlannedIncidentItem.propTypes = {
  item: PropTypes.object,
};
