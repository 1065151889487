import React from 'react';
import PropTypes from 'prop-types';
import isWithinInterval from 'date-fns/isWithinInterval';
import subHours from 'date-fns/subHours';

import HistoricIncidentCard from './HistoricIncidentCard';

import './incidents-list.css';

export default function IncidentsList({ incidents }) {
  const incidentsLast48Hours = incidents.filter((e) => {
    if (e.status !== 'resolved') return e;

    let now = new Date();
    let earliestDate = subHours(now, 48);
    const yes = isWithinInterval(new Date(e.started_at), { start: earliestDate, end: new Date() });
    if (yes) {
      return e;
    }
  });

  const list = incidentsLast48Hours.map((i) => {
    return (
      <li key={i.id}>
        <HistoricIncidentCard item={i} />
      </li>
    );
  });
  return <ul className="dnb-incidents-list">{list}</ul>;
}

IncidentsList.propTypes = {
  incidents: PropTypes.array,
};
