import React from 'react';
import PropTypes from 'prop-types';
import getISOWeek from 'date-fns/getISOWeek';
import getYear from 'date-fns/getYear';

import { Icon } from '@dnb/eufemia/components';
import { chevron_left as ChevronLeft } from '@dnb/eufemia/icons';
import { chevron_right as ChevronRight } from '@dnb/eufemia/icons';

import './month-selecter.css';

export default function MonthPicker({ date, incrementWeek, decrementWeek }) {
  const week = getISOWeek(date);
  const year = getYear(date);

  return (
    <div className="dnb-month-picker">
      <button
        className="dnb-small-button dnb-small-button--prev"
        onClick={() => decrementWeek()}
        aria-label="Gå til forrige uke"
      >
        <Icon icon={ChevronLeft} size="25" aria-hidden />
      </button>
      <div className="dnb-month-picker__current-week">
        <span>
          Uke {week} {year}
        </span>
      </div>
      <button
        className="dnb-small-button dnb-small-button--next"
        onClick={() => incrementWeek()}
        aria-label="Gå til neste uke"
      >
        <Icon icon={ChevronRight} size="25" aria-hidden />
      </button>
    </div>
  );
}

MonthPicker.propTypes = {
  date: PropTypes.instanceOf(Date),
  incrementWeek: PropTypes.func,
  decrementWeek: PropTypes.func,
};
