import React from 'react';
import PropTypes from 'prop-types';

import GroupsWrapper from './components/groupsWrapper/GroupsWrapper';
import StatusMessagesWrapper from './components/statusMessagesWrapper/StatusMessagesWrapper';
import Legend from './components/legend/Legend';
import FrontpageComponents from './components/frontpage-components/FrontpageComponents';
import { addIncidentsToGroup } from './helpers';

export default function HomePage({ groups, incidents, maintenances, updateTimestamp, includedComponents }) {
  const groupsWithIncidents = addIncidentsToGroup(groups, incidents);

  return (
    <main className="p_home-page" role="main">
      <section className="dnb-center-content">
        <StatusMessagesWrapper
          incidents={incidents}
          updateTimestamp={updateTimestamp}
          includedComponents={includedComponents}
        />
        <FrontpageComponents
          maintenances={maintenances}
          groups={groupsWithIncidents}
          includedComponents={includedComponents}
        
        />
        <Legend />
      </section>
      <section className="dnb-center-content">
        <GroupsWrapper groups={groupsWithIncidents} incidents={incidents} />
        
      </section>
    </main>
  );
}

HomePage.propTypes = {
  groups: PropTypes.array,
  incidents: PropTypes.array,
  isFetchingStatusPage: PropTypes.bool,
  maintenances: PropTypes.array,
  updateTimestamp: PropTypes.instanceOf(Date),
  includedComponents: PropTypes.object,
};
