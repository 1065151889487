import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import differenceInDays from 'date-fns/differenceInDays';

import GroupListItem from './GroupListItem';
import IconHeader from '../layout/IconHeader';

import './group.css';

import compareDesc from 'date-fns/compareDesc';

const Group = ({ item }) => {
  const { t, i18n } = useTranslation();
  const daysSinceIncidentNumber = () => {
    if (item.incidents.length === 0) return '00';
    else {
      let sorted = item.incidents.sort((a, b) => {
        return compareDesc(new Date(a.started_at), new Date(b.started_at));
      });
      let days = differenceInDays(new Date(), new Date(sorted[0].started_at));

      if (days < 10) return `0${days}`;
      else return days.toString();
    }
  };

  const daysSinceIncidentText = () => {
    if (item.incidents.length === 0) {
      return (
        <>
          <span>{t('No incidents in')}</span>
          <span>{t('over')} 3 {t('months')}</span>
        </>
      );
    } else {
      return (
        <>
          <span>{t('days since')}</span>
          <span>{t('last')} {t('incident')}</span>
        </>
      );
    }
  };

  return (
    <article className='dnb-group'>
      <IconHeader name={t(item.name)} icon={item.name} />
      <div className='dnb-group-content'>
        <div className='dnb-group-content__left'>
          <div className='dnb-incident-counter'>
            <span className='dnb-incident-counter__number'>{daysSinceIncidentNumber()}</span>
            <span className='dnb-incident-counter__text'>{daysSinceIncidentText()}</span>
          </div>
        </div>
        <div className='dnb-group-content__right'>
          <div className='dnb-status-list'>
            <ul>
              {item.subComponents.map((el) => {
                return <GroupListItem item={el} key={el.id} />;
              })}
            </ul>
          </div>
        </div>
      </div>
    </article>
  );
};

Group.propTypes =
  {
    item: PropTypes.object,
    name
:
PropTypes.string,
}
;

export default Group;
