import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Icon } from '@dnb/eufemia/components';
// import { chevron_right as ChevronRight } from '@dnb/eufemia/icons';
import DnbStatusLink from '../dnb-status-link/DnbStatusLink';
import CustomDnbLogoIcon from '../../assets/icons/custom_dnb_logo.svg';

import './header.css';

const Header = () => {
  const { t, i18n } = useTranslation();
  return (
    <header className="dnb-header">
      <div className="dnb-center-content">
        <div className="dnb-header__content">
          <Link
            to="/"
            aria-label={t('DNB Status front page')}
            title={t('DNB Status front page')}
            className="dnb-main-title"
          >
            <h1>DNB Status</h1>
            <span className="dnb-main-title__logo">
              <Icon size="auto" icon={CustomDnbLogoIcon} aria-hidden />
            </span>
            <span className="dnb-main-title__sub">Status</span>
          </Link>
          <DnbStatusLink href="https://www.dnb.no/" external aria-label={t('Go to dnb.no')} title={t('Go to dnb.no')}>
            dnb.no
          </DnbStatusLink>
        </div>
      </div>
    </header>
  );
};

export default Header;
