import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { Icon } from '@dnb/eufemia/components';

import { getStatusIcon } from '../../helpers';

import './legend.css';

const Legend = (props) => {
  const { t, i18n } = useTranslation();
  let legendClass = ClassNames('dnb-legend', {
    'dnb-legend--left': props.iconPosition === 'left',
  });

  const LegendData = [
    {
      id: 'operational',
      status: t('operational'),
    },
    {
      id: 'minor',
      status: t('minor'),
    },
    {
      id: 'major',
      status: t('major'),
    },
    {
      id: 'critical',
      status: t('critical'),
    },
    {
      id: 'under_maintenance',
      status: t('under_maintenance'),
    },
  ];

  return (
    <div className={legendClass}>
      <ul>
        {LegendData.length > 0 &&
          LegendData.map((item) => {
            return (
              <li key={`legend-${item.id}`}>
                <span className={`dnb-status-icon dnb-status-icon--${item.id}`}>
                  <Icon icon={getStatusIcon(item.id)} size="20" />
                </span>
                <span className="dnb-legend__text">{item.status}</span>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default Legend;

Legend.propTypes = {
  iconPosition: PropTypes.string,
};
