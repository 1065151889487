import { useEffect, useState } from 'react';

const GENERIC_ERROR =
  'Vi har noen problemer som gjør at vi ikke klarer å innhente status på systemene våre. Det skal ikke påvirke selve driften av systemene, så forhåpentlig fungerer alt fint. Sjekk gjerne innom igjen om noen minutter.';
/*
 * Incidents: incidentsType: "" to get all incidents, or "unresolved"
 * maintenances: scheduledMaintenancesType: "" to get all, or "active" or "upcoming"
 */
export default function useStatusPage({
                                        page,
                                        getIncidents = true,
                                        getComponents = true,
                                        incidentsType = '',
                                        getScheduledMaintenances = true,
                                        scheduledMaintenancesType = {
                                          in_progress: true,
                                          scheduled: false,
                                          completed: false,
                                        },
                                        updateInterval = null,
                                        includedComponents = {},
                                      }) {
  const [groups, setGroups] = useState([]);
  const [incidents, setIncidents] = useState([]);
  const [maintenances, setMaintenances] = useState([]);
  const [error, setError] = useState(null);
  const [updateTimestamp, setUpdateTimestamp] = useState(null);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    getComponents && fetchComponents();
    getIncidents && fetchIncidents(incidentsType);
    getScheduledMaintenances && fetchScheduledMaintenances(scheduledMaintenancesType);

    if (updateInterval && !intervalId) {
      const id = setInterval(() => {
        getComponents && fetchComponents();
        getIncidents && fetchIncidents(incidentsType);
        getScheduledMaintenances && fetchScheduledMaintenances(scheduledMaintenancesType);
      }, updateInterval);

      setIntervalId(id);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };

    async function fetchScheduledMaintenances(filter) {
      let url;
      url = '/scheduled-maintenances.json';
      const { data, error } = await getStatusPageData(url);
      if (!error) {
        let scheduled_maintenances = data.scheduled_maintenances.filter((e) => {
          if (filter[e.status]) {
            return e;
          }
        });
        setMaintenances(scheduled_maintenances);
      } else {
        setError(error);
      }
    }

    async function fetchIncidents(filter) {
      let url;

      if (filter === '') {
        url = '/incidents.json';
      } else {
        url = `/incidents/${filter}.json`;
      }

      const { data, error } = await getStatusPageData(url);

      if (!error) {
        setIncidents(data.incidents);
        setUpdateTimestamp(new Date());
      } else {
        setError(error);
      }
    }

    async function fetchComponents() {
      const { data, error } = await getStatusPageData('/components.json');

      if (!error) {
        const tempGroups = [];

        for (let component of data.components) {
          component.incidents = [];

          if (component.group) {
            component.subComponents = component.components.map((id) => data.components.find((e) => e.id === id));
            if (includedComponents.hasOwnProperty(component.id)) {
              console.log('Included ' + component.id + ' ' + component.name);
              tempGroups.push(component);
            } else {
              console.log('Not included ' + component.id + ' ' + component.name);
            }
          }
        }
        setGroups(tempGroups);
      } else {
        setError(error);
      }
    }

    async function getStatusPageData(endpoint) {
      try {
        const url = `https://${page}.statuspage.io/api/v2${endpoint}`;
        let response = await fetch(url);

        if (response.ok) {
          let data = await response.json();

          return { data };
        } else {
          return { error: GENERIC_ERROR };
        }
      } catch (error) {
        return { error: GENERIC_ERROR };
      }
    }
  }, [
    getComponents,
    getIncidents,
    getScheduledMaintenances,
    incidentsType,
    scheduledMaintenancesType,
    updateInterval,
    intervalId,
    page,
  ]);

  return { groups, incidents, maintenances, error, updateTimestamp };
}
