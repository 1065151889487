import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import isWithinInterval from 'date-fns/isWithinInterval';
import subHours from 'date-fns/subHours';

import IconHeader from '../layout/IconHeader';
import InfoCircle from '../info-circle/InfoCircle';

import './status-overview.css';

const StatusOverview = ({ groups }) => {
  const { t, i18n } = useTranslation();
  const [incidentsLast24, setIncidentsLast24] = useState(0);
  const [operational, setOperational] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const getIncidentsLast24Hours = () => {
      let total = 0;
      let now = new Date();
      let earliestDate = subHours(now, 24);
      for (let group of groups) {
        let incidents = group.incidents.filter((e) => {
          const yes = isWithinInterval(new Date(e.started_at), { start: earliestDate, end: new Date() });
          if (yes) {
            return e;
          }
        });
        total += incidents.length;
      }
      setIncidentsLast24(total);
    };

    getIncidentsLast24Hours();

    const oper = () => {
      let operational = 0;
      let total = 0;
      for (let group of groups) {
        const op = group.subComponents.filter((e) => e.status === 'operational').length;
        const t = group.subComponents.length;

        operational += op;
        total += t;
      }

      return { operational, total };
    };
    const { operational, total } = oper();
    setOperational(operational);
    setTotal(total);
  }, [groups]);

  return (
    <div className="dnb-status-overview">
      <IconHeader name={t('Incidents')} subtitle={t('Last 48 hours')} icon="status" />
      <InfoCircle topText={t('Incidents')} number={incidentsLast24} bottomText={t('last 24 hours')} />
      <InfoCircle topText={t('Systems')} number={`${operational}/${total}`} bottomText={t('working')} />
    </div>
  );
};

StatusOverview.propTypes = {
  groups: PropTypes.array,
};

export default StatusOverview;
