import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ClassNames from 'classnames';

import { Icon } from '@dnb/eufemia/components';
import './dnb-status-link.css';

export default function DnbStatusLink(props) {
  let linkClass = ClassNames('dnb-status-link', {
    'dnb-status-link--right': props.iconPosition === 'right',
  });
  if (props.external) {
    return (
      <a
        href={props.href}
        className={linkClass}
        to={props.href}
        title={props.title}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.icon && <Icon icon={props.icon} size="auto" aria-hidden />}
        <span className="dnb-button__text">{props.children}</span>
      </a>
    );
  }
  return (
    <Link className={linkClass} to={props.href} title={props.title} relative="path">
      {props.icon && <Icon aria-hidden icon={props.icon} size="auto" />}
      <span className="dnb-button__text">{props.children}</span>
    </Link>
  );
}

DnbStatusLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.any,
  iconPosition: PropTypes.string,
  external: PropTypes.bool,
};
