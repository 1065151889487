import React from 'react';
import PropTypes from 'prop-types';

import SquareFiller from '../../assets/images/square-filler.png';
import './info-circle.css';

export default function InfoCircle(props) {
  return (
    <div className="dnb-info-circle">
      <img
        src={SquareFiller}
        className="dnb-info-circle__filler-square"
        alt="Gjennomsiktig, lite firkantet bilde bare brukt for å stilsette infosirkelen"
        aria-hidden
      />
      <div className="dnb-info-circle__content">
        <span className="dnb-info-circle__top-text">{props.topText}</span>
        <span className="dnb-info-circle__number">{props.number}</span>
        <span className="dnb-info-circle__bottom-text">{props.bottomText}</span>
      </div>
    </div>
  );
}

InfoCircle.propTypes = {
  topText: PropTypes.string.isRequired,
  number: PropTypes.any.isRequired,
  bottomText: PropTypes.string.isRequired,
};
