import React from 'react';
import { useTranslation } from 'react-i18next';

import NoIncidentsIllustration from '../../assets/images/ingen_hendelser_illustrasjon.png';
import { chevron_left as ChevronLeft } from '@dnb/eufemia/icons';
import { chevron_right as ChevronRight } from '@dnb/eufemia/icons';

import DnbStatusLink from '../dnb-status-link/DnbStatusLink';
import './no-incidents.css';

export default function NoIncidents() {
  const { t, i18n } = useTranslation();
  return (
    <div className="dnb-no-incidents">
      <div className="dnb-center-content">
        <h3>{t('Not much is happening here')}...</h3>
        <span>{t('No incidents in the last 48 hours')}.</span>
        <div className="dnb-no-incidents__buttons">
          <DnbStatusLink aria-hidden href="/" title={t('Back to the front page')} icon={ChevronLeft}>
            {t('Back to the front page')}
          </DnbStatusLink>
          <DnbStatusLink
            href="https://www.dnb.no/"
            title={t('Go to dnb.no')}
            iconPosition="right"
            icon={ChevronRight}
            external
          >
            {t('Go to dnb.no')}
          </DnbStatusLink>
        </div>
        <figure className="dnb-image-wrapper">
          <img
            src={NoIncidentsIllustration}
            alt="En tegning av en avslappet person for å illustrere at det ikke er noen aktive hendelser akkurat nå."
          />
        </figure>
      </div>
    </div>
  );
}
