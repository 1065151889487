import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import IncidentsList from './IncidentsList';
import Legend from '../legend/Legend';

import './incidents-list-wrapper.css';

export default function IncidentsListWrapper({ incidents }) {
  const { t, i18n } = useTranslation();
  return (
    <div className="dnb-incidents-list-wrapper">
      <div className="dnb-center-content">
        <div className="dnb-incidents-list-wrapper__header">
          <h3 className="dnb-incidents-list-wrapper__title">{t('Ongoing incidents')}</h3>
        </div>
        <IncidentsList incidents={incidents} />
        <Legend iconPosition="left" />
      </div>
    </div>
  );
}

IncidentsListWrapper.propTypes = {
  incidents: PropTypes.array,
};
