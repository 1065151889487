import React from 'react';

import PropTypes from 'prop-types';
import format from 'date-fns/format';

import { Icon } from '@dnb/eufemia/components';

import { getStatusIcon } from '../../helpers';
import './status-message.css';
import { useTranslation } from 'react-i18next';
import FindLanguage from "../../i18n_custom";
import { isBankIDIncident } from '../../helpers';

const StatusMessage = ({ incident }) => {
  const { t, i18n } = useTranslation();

  if (isBankIDIncident(incident)) {
    incident.incident_updates[0].body = "Leverandør jobber med saken.||Vendor is working to resolve the issue.";
  }
  return (
    <li role="status" className={`dnb-status-message dnb-status-message--${incident.impact}`}>
      <span className={`dnb-status-icon dnb-status-icon--${incident.impact}`}>
        <Icon icon={getStatusIcon(incident.impact)} aria-hidden size="20" />
      </span>
      <span className="dnb-status-message__text dnb-h--medium">{FindLanguage(incident.name)}</span>
      <span className="dnb-status-message__time">
        <time dateTime={format(new Date(incident.started_at), 'dd.MM.yyyy, HH:mm')}>
          {format(new Date(incident.started_at), 'dd.MM.yyyy, HH:mm')}
        </time>
      </span>
      <span className="dnb-status-message__time">
        {FindLanguage(incident.incident_updates[0].body)}
      </span>
    </li>
  );
};

StatusMessage.propTypes = {
  incident: PropTypes.object,
};


export default StatusMessage;
