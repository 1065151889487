import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import StatusMessage from '../statusMessage/StatusMessage';
import DnbStatusLink from '../dnb-status-link/DnbStatusLink';
import format from 'date-fns/format';
import { chevron_right as ChevronRight } from '@dnb/eufemia/icons';
import './status-messages-wrapper.css';

const StatusMessageWrapper = ({ incidents, updateTimestamp, includedComponents }) => {
  const { t, i18n } = useTranslation();
  const incidentsList = incidents
    .filter((e) => e.status !== 'resolved')
    .filter((e) => {
      if (e.components.length > 0) {
        if (e.components[0].id in includedComponents) {
          return e;
        }
      } else {
        return e;
      }
    })                  // If we want to filter out only the components that are affected

    .map((incident) => {
      return <StatusMessage key={incident.id} incident={incident} />;
    });


  const lastUpdated = updateTimestamp !== null ? format(updateTimestamp, 'dd.MM.yyyy, HH:mm') : '';

  // Dirty hack to get relative path on incidents link
  var incidents_location = '';
  if (location.pathname === "/") {
    incidents_location = '/incidents';
  }
  else {
    incidents_location = location.pathname + '/incidents';
  }
  if (incidentsList.length > 0) {
    return <ul className='dnb-status-messages-wrapper'>{incidentsList}</ul>;
  } else {
    return (
      <div className='dnb-no-statuses'>
        <div>
          <h2>{t('All our systems and services are working as normal')}</h2>
          <span className='dnb-no-statuses__last-updated'>
            {t('Last updated')}: <time dateTime={lastUpdated}>{lastUpdated}</time>
          </span>
        </div>

        <DnbStatusLink
          href={incidents_location}
          icon={ChevronRight}
          title={t('Go to the incident overview')}
          iconPosition='right'
        >
          {t('See incidents')}
        </DnbStatusLink>
      </div>
    );
  }
};

export default StatusMessageWrapper;

StatusMessageWrapper.propTypes = {
  incidents: PropTypes.array,
  updateTimestamp: PropTypes.instanceOf(Date),
  includedComponents: PropTypes.object,
};
