import React from 'react';
import PropTypes from 'prop-types';
import Group from '../group/Group';
import './groups-wrapper.css';

const GroupsWrapper = ({ groups }) => {
  return (
    <div className="dnb-groups-wrapper">
      {groups.length > 0 && groups.map((item) => <Group key={item.id} item={item} />)}
    </div>
  );
};

GroupsWrapper.propTypes = {
  groups: PropTypes.array,
  incidents: PropTypes.array,
};

export default GroupsWrapper;
