import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Icon } from '@dnb/eufemia/components';

import CustomDnbCalendar from '../../assets/icons/custom_dnb_calendar.svg';
import { chevron_left as ChevronLeft } from '@dnb/eufemia/icons';

import DnbStatusLink from '../dnb-status-link/DnbStatusLink';
import MonthSelecter from '../monthSelecter/MonthSelecter';

import './history-header.css';

const HistoryHeader = ({ date, incrementWeek, decrementWeek }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="dnb-history-header">
      {/* <div className="dnb-center-content"> */}
      <div className="dnb-history-header__actions">
        <DnbStatusLink href="/" title={t('Back to the front page')} icon={ChevronLeft}>
          {t('Back')}
        </DnbStatusLink>

        <DnbStatusLink href="/" title={t('Subscribe to updates')}>
          {t('Subscribe to updates')}
        </DnbStatusLink>
      </div>
      <div className="dnb-history-header__content">
        <h2>
          <Icon icon={CustomDnbCalendar} size="auto" aria-hidden />
          {t('History')}
        </h2>
        <MonthSelecter date={date} decrementWeek={decrementWeek} incrementWeek={incrementWeek} />
      </div>
      {/* </div> */}
    </div>
  );
};

HistoryHeader.propTypes = {
  date: PropTypes.instanceOf(Date),
  incrementWeek: PropTypes.func,
  decrementWeek: PropTypes.func,
};

export default HistoryHeader;
