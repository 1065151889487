import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { Icon } from '@dnb/eufemia/components';
import { getIcon } from '../../helpers';

import './icon-header.css';

export default function IconHeader({ name, subtitle, pageHeader, icon }) {
  let headerClass = ClassNames('dnb-icon-header', {
    'dnb-icon-header--page-header': pageHeader,
  });

  return (
    <div className={headerClass}>
      <span className="dnb-icon-header__icon">
        <Icon size="auto" icon={getIcon(icon)} aria-hidden />
      </span>
      <h2>
        {name && <>{name}</>} {subtitle && <span className="dnb-icon-header__subtitle">{subtitle}</span>}
      </h2>
    </div>
  );
}

IconHeader.propTypes = {
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  pageHeader: PropTypes.bool,
  icon: PropTypes.string.isRequired,
};
