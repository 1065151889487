import React from 'react';

import CustomDnbLoading from '../../assets/icons/custom_dnb_loading.svg';

import './loading.css';

const Loading = () => {
  return (
    <header className="dnb-loading">
      <span className="dnb-loading__icon">
        <CustomDnbLoading />
      </span>
    </header>
  );
};

export default Loading;
