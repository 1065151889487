import React from 'react';

import './footer.css';

const Footer = () => {
  return (
    <footer className="dnb-footer">
      <div className="dnb-center-content">
        <div className="dnb-footer__content">
          <span className="dnb-footer__copyright">Copyright DNB ASA</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
