import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Icon } from '@dnb/eufemia/components';

import { getStatusIcon } from '../../helpers';

export default function GroupListItem({ item }) {
  const { t, i18n } = useTranslation();
  let iconClass = ClassNames('dnb-status-icon', {
    'dnb-status-icon--operational': item.status === 'operational',
    'dnb-status-icon--minor': (item.status === 'minor_outage' || item.status === 'degraded_performance' || item.status === 'partial_outage'),
    'dnb-status-icon--major': item.status === 'major_outage',
    'dnb-status-icon--under_maintenance': item.status === 'under_maintenance',
  });
  return (
    <li className='dnb-status-list__item'>
      <div className={iconClass}>
        <Icon size='20' icon={getStatusIcon(item.status)} aria-hidden />
      </div>
      <span>{t(item.name)}</span>
    </li>
  );
}

GroupListItem.propTypes = {
  item: PropTypes.object,
  name: PropTypes.string,
};
