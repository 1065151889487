import { useState } from 'react';
import addWeeks from 'date-fns/addWeeks';
import subWeeks from 'date-fns/subWeeks';
export default function useMonthSelecter(initialDate) {
  const [date, setDate] = useState(initialDate);

  function incrementWeek() {
    let newDate = addWeeks(date, 1);
    setDate(newDate);
  }

  function decrementWeek() {
    let newDate = subWeeks(date, 1);
    setDate(newDate);
  }

  return { date, incrementWeek, decrementWeek };
}
