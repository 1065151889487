import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FindLanguage from '../../i18n_custom';
import PropTypes from 'prop-types';

import { Icon } from '@dnb/eufemia/components';
import compareDesc from 'date-fns/compareDesc';
import format from 'date-fns/format';
import { nb } from 'date-fns/locale';

import { getStatusIcon } from '../../helpers';

import { chevron_right as ChevronRight } from '@dnb/eufemia/icons';
import './historic-incident-card.css';

export default function HistoricIncidentCard({ item }) {
  const { t } = useTranslation();
  const [isClosed, setClosed] = useState([true]);

  const date = new Date(item.started_at);
  const formattedDate = format(date, 'dd');
  const formattedMonth = format(date, 'MMM', { locale: nb });

  const sortedIncidentUpdates = item.incident_updates.sort((a, b) => {
    return compareDesc(new Date(a.created_at), new Date(b.created_at));
  });

  const formattedIncidentStatusDate = (date) => {
    let dateObj;
    if (typeof date === 'string') {
      dateObj = new Date(date);
    } else {
      dateObj = date;
    }

    return format(dateObj, 'dd.MM.yy HH:mm');
  };

  //const affectedComponentsString = item.components.map((e) => e.name).join(', ');
  // Trying to map with translated names
  const affectedComponentsString = item.components.map((e) => t(e.name)).join(', ');

  return (
    <article className={`dnb-historic-incident-card dnb-historic-incident-card--${item.impact}`}>
      <div className="dnb-card-date">
        <span className="dnb-card-date__day">{formattedDate}</span>
        <span className="dnb-card-date__month">{formattedMonth}</span>
      </div>
      <div className="dnb-card-text">
        <h3>{FindLanguage(item.name)}</h3>
        <span>
          {t('Incident')} {t('affected')}: {affectedComponentsString}
        </span>
        <span>
          {t('Incident')} {t('started')}: <span>{formattedIncidentStatusDate(item.started_at)}</span>
        </span>
        {item.resolved_at && (
          <span>
            {t('Incident')} {t('resolved')}: <span>{formattedIncidentStatusDate(item.resolved_at)}</span>
          </span>
        )}
        {item.incident_updates.length > 0 && (
          <button
            aria-expanded={isClosed === true ? 'false' : 'true'}
            className={`dnb-status-text-button ${isClosed ? '' : 'dnb-status-text-button--clicked'}`}
            onClick={() => setClosed(!isClosed)}
          >
            <span className="dnb-status-text-button__text">{isClosed ? t('Show log') : t('Hide log')}</span>
            <span className="dnb-status-text-button__icon">
              <Icon icon={ChevronRight} size="20" aria-hidden />
            </span>
          </button>
        )}
        {/* <span className="dnb-card-text__current-status">{sortedIncidentUpdates[0].body}</span> */}
      </div>
      <span className={`dnb-status-icon dnb-status-icon--${item.impact}`}>
        <Icon class="" icon={getStatusIcon(item.impact)} aria-hidden size="25" />
      </span>
      {item.incident_updates.length > 0 && (
        <div className={`dnb-incident-history ${isClosed ? '' : 'dnb-incident-history--open'}`}>
          <ul>
            {item.incident_updates.map((e) => {
              return (
                <li key={e.id}>
                  <span className="dnb-incident-history__body">{FindLanguage(e.body)}</span>
                  <span className="dnb-incident-history__date">{formattedIncidentStatusDate(e.updated_at)}</span>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </article>
  );
}

HistoricIncidentCard.propTypes = {
    item: PropTypes.object,
};
