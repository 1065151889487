// Function to extract string on the left of || and put in norwegian, and on the right to english

import { useTranslation } from 'react-i18next';

//Function takes a string, if it finds ||, it assumes that string on the left is norwegian, and on the right is english
//the string it finds, it runs through the i18n t function, which further translates it
const FindLanguage = (incidentText) => {
  const { t, i18n } = useTranslation();
  console.log('Language: ' + i18n.language);
  let incidentTextInLanguage = incidentText;
  let splitter = incidentText.indexOf('||');

  // If we find splitter in the text
  if (splitter !== -1) {
    console.log('Found language splitter');
    // If the detected language is norwegian we select the left side of the string
    if (i18n.language === 'nb-NO' || i18n.language === 'no' || i18n.language === 'nb') {
      incidentTextInLanguage = incidentText.substring(0, splitter);
    }
    // If it is not norwegian we select the right side of the string (starting 2 characters || to the right)
    else {
      incidentTextInLanguage = incidentText.substring(2 + splitter);
    }
    // Else we do not find a splitter, and we return the string untouched.
  } else {
    console.log('Did not find language splitter for text: -->' + incidentTextInLanguage + '<--');
    //console.log('With translation it is: ' + t(incidentTextInLanguage));
  }
  console.log('Returning this text: ' + t(incidentTextInLanguage));
  return t(incidentTextInLanguage);
};

export default FindLanguage;
